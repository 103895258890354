<!--
 * @Description: 
 * @Author: 琢磨先生
 * @Date: 2023-02-16 23:33:27
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2024-04-01 10:22:22
-->
<template>
    <div class="home">
      <div class="logo">
        <img alt="Vue logo" src="../../assets/logo.png" />
      </div>
      <div class="desc">
        <h1>欢迎使用德亦报备管理系统</h1>
        <p>德亦房地产的报备管理平台，专业、便捷、迅速！</p>
        <!-- <h3>系统入口</h3> -->
        <ul>
          <li>
            <el-link
              :underline="false"
              type="success"
              href="//agent.dezhaiyoujia.com/field/login"
            >案场客服</el-link>
          </li>
          <li>
            <el-link :underline="false" @click="oaLogin" type="success">管理中心</el-link>
          </li>
        </ul>
      </div>
      <div class="icp">琼ICP备2020004009号-2</div>
    </div>
  </template>
  
  <script>
  export default {
    components: {},
    created() {},
    methods: {
      /**
       *
       */
      oaLogin() {
        this.$http.get("admin/v1/login/oauth").then((res) => {
          if (res.code == 0) {
            var host = `${window.location.protocol}//${window.location.host}`;
  
            var url = `${res.data}&redirect_uri=${encodeURIComponent(
              host + "/login/oauth"
            )}`;
            window.location.href = url;
          }
        });
      },
    },
  };
  </script>
  
  <style scoped>
  .home {
    /* text-align: center; */
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .home .logo {
    padding: 60px 0;
  }
  .home .desc {
    flex-grow: 1;
    /* padding-top: 60px; */
  }
  
  .home .icp {
    font-size: 12px;
    color: #909399;
    padding: 20px 0;
  }
  
  h3 {
    margin: 40px 0 0;
  }
  ul {
    list-style-type: none;
    padding: 0;
    text-align: center;
  }
  li {
    display: inline-block;
    margin: 0 10px;
  }
  a {
    color: #42b983;
  }
  </style>
  